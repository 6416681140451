import React from 'react';
// import { Link } from 'react-router-dom';

import '../Assets/font/icons.scss';
import '../Assets/components/Url.scss';

function Url ({title}) {
  return (
    <div className="head">
      <div className="head__options">
        <div className="head__options__circle"></div>
        <div className="head__options__circle"></div>
        <div className="head__options__circle"></div>
      </div>
      <div className="head__link">
        <div className="head__link__url">
          <i className="icon-lock"></i>
          <span>{title}</span>
        </div>
        <div className="head__link__go">
          <a href={`${title}`} target='_blank'>
            <i className="icon-arrow-circle-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Url;