import React from 'react';

import '../Assets/components/Card.scss';

import Window from './Window';

function Card ({title, url, description}) {
  return (
    <div className='card'>
      <h3 className  = 'title'><a href={url}>{title}</a></h3>
      <Window
        title = { title }
        url = {url}
      />
      <p className   = 'description'>{description}</p>
    </div>
  );
}

export default Card;