import React from 'react';

import '../Assets/components/Footer.scss';
import heart from '../Assets/img/heart_mdesigner.png';

function Footer () {
  return (
    <footer className='footer'>
      <p className='footer__p'>
        creado con <img src={heart} alt="amor"/> por <a href='https://mdesigner.co'>mdesigner.co</a>
      </p>
    </footer>
  );
}

export default Footer;