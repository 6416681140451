import React from 'react';

import '../Assets/components/Window.scss';
import Url from './Url';

function Window ({title, url}) {
  return (
    <div className = 'window'>
      <Url
        title = {url}
      />
      <iframe
        className = 'window__view'
        src       = {url}
        title     = {title}>
      </iframe>
    </div>
  );
}

export default Window;