import React from 'react';

import '../Assets/components/Content.scss';

import Card from './Card';
import Button from './Button';

import Data from '../data.json';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: []
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async() => {
    try {
      this.setState( { sites: Data.portfolio } )
    } catch ( error ) {
      return console.error(error);
    }
  }

  render() {
    return (
      <section className='content'>
        <div className='content__carousel'>
          {
            this.state.sites.map( site => {
              return <div className='content__carousel__item'>
                <Card key = {site.id}
                  title = {site.title}
                  url = {site.url}
                  description = {site.description}
                />
              </div>
            }).reverse()
          }
        </div>
        <div className="content__contact">
          <Button />
        </div>
      </section>
    );
  }
}

export default Content;