import React from 'react';
import mdesignerLogo from '../Assets/img/logo-mdesigner.svg';
import '../Assets/components/Nav.scss';
import '../Assets/font/icons.scss';

function Navbar () {

  return (
    <nav className="navbar">
      <div className="navbar__logo">
        <a href="http://mdesigner.co" target='_blank'> <img src={mdesignerLogo} alt="mdesigner logo"/> </a>
      </div>
      <ul className="navbar__social">
        <li className="navbar__social__item"><a href="https://www.linkedin.com/in/jmcgraphics/" target='_blank'><i className="icon-linkedin"></i></a></li>
        <li className="navbar__social__item"><a href="https://www.behance.net/mdesigner_co" target='_blank'><i className="icon-behance"></i></a></li>
        <li className="navbar__social__item"><a href=" https://github.com/mdesignerco" target='_blank'><i className="icon-github-alt"></i></a></li>
      </ul>
    </nav>
  );
};

export default Navbar;

