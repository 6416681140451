import React from 'react';

import '../Assets/components/Buttons.scss';

class ButtonContact extends React.Component {
  render() {
    return (
      <div className="contactBtn">
        <h4 className='title'>Listo para que trabajemos en tu próximo proyecto?</h4>
          <a href='https://api.whatsapp.com/send?phone=573059007648' target='_blank'>
            <button className="btn-whatsapp">
              <i className="icon-whatsapp"></i>
            </button>
          </a>
      </div>
    );
  }
}

export default ButtonContact;